import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./table.scss";

export const Table = ({ className, ...props }) => (
  <table className={classNames("table", className)} {...props} />
);

export const TableCaption = ({ className, ...props }) => (
  <caption className={classNames("table__caption", className)} {...props} />
);

export const TableThead = ({ className, ...props }) => <thead className={className} {...props} />;
export const TableTbody = ({ className, ...props }) => <tbody className={className} {...props} />;
export const TableTfoot = ({ className, ...props }) => <tfoot className={className} {...props} />;
export const TableTr = ({ className, ...props }) => (
  <tr className={classNames("table__row", className)} {...props} />
);
export const TableTh = ({ className, alignRight, ...props }) => (
  <th
    className={classNames(
      "table__cell table__cell--header",
      { "table__cell--align-right": alignRight },
      className
    )}
    {...props}
  />
);
export const TableTd = ({ className, thText, alignRight, ...props }) => (
  <td
    className={classNames("table__cell", { "table__cell--align-right": alignRight }, className)}
    data-th-text={thText}
    {...props}
  />
);

const sharedPropTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Table.propTypes = {
  ...sharedPropTypes,
};

TableCaption.propTypes = {
  ...sharedPropTypes,
};

TableThead.propTypes = {
  ...sharedPropTypes,
};

TableTbody.propTypes = {
  ...sharedPropTypes,
};

TableTfoot.propTypes = {
  ...sharedPropTypes,
};

TableTr.propTypes = {
  ...sharedPropTypes,
};

TableTh.propTypes = {
  ...sharedPropTypes,
};

TableTd.propTypes = {
  ...sharedPropTypes,
  thText: PropTypes.string.isRequired,
};
