import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import uuid from "uuid";

// eslint-disable-next-line no-unused-vars
import { pagesDataQuery, wcagDataQuery } from "../queries/queries";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import { Spinner } from "../components/spinner/spinner";

import {
  Table,
  TableCaption,
  TableThead,
  TableTbody,
  TableTr,
  TableTh,
  TableTd,
} from "../components/table/table";

import { Collapse, CollapseButton, CollapseContent } from "../components/collapse/collapse";

const TestPage = ({ data }) => {
  const pagesData = data.allPagesJson.edges;
  const csvData = data.allCsvCsv.nodes;
  const wcagCriterias = data.allDataJson.edges[0].node.principles;
  const [isLoading, setIsLoading] = useState(true);
  const [sumup, setSumup] = useState([]);

  useEffect(() => {
    let sumupArray = [];

    csvData.forEach((data, index) => {
      const pageData = pagesData[index].node;

      data.data.forEach(item => {
        if (item.code === "") return;
        const criteria = item.code.split(".")[3].replace(/_/g, ".");
        const principle = criteria.charAt(0);
        const principleIndex = wcagCriterias.findIndex(obj => obj.num === principle);
        const guideline = criteria.substring(0, 3);

        const guidelineIndex = wcagCriterias[principleIndex].guidelines.findIndex(
          obj => obj.num === guideline
        );

        const criteriaIndex = wcagCriterias[principleIndex].guidelines[
          guidelineIndex
        ].successcriteria.findIndex(obj => obj.num === criteria);

        const message =
          wcagCriterias[principleIndex].guidelines[guidelineIndex].successcriteria[criteriaIndex]
            .title;

        const specialCases =
          wcagCriterias[principleIndex].guidelines[guidelineIndex].successcriteria[criteriaIndex]
            .details;

        if (sumupArray.some(el => el.name === criteria)) {
          const indexInArray = sumupArray.findIndex(obj => obj.name === criteria);

          const tempCount = sumupArray[indexInArray].count;
          sumupArray[indexInArray].count = tempCount + 1;

          if (sumupArray[indexInArray].groups.some(el => el.name === pageData.name)) {
            // Page already exist
            const indexInGroupsArray = sumupArray[indexInArray].groups.findIndex(
              obj => obj.name === pageData.name
            );
            const tempGroupsCount = sumupArray[indexInArray].groups[indexInGroupsArray].count;

            sumupArray[indexInArray].groups[indexInGroupsArray].count = tempGroupsCount + 1;
          } else {
            // Page doesn't exist
            const tempGroups = sumupArray[indexInArray].groups;
            sumupArray[indexInArray].groups = [
              ...tempGroups,
              {
                id: uuid.v4(),
                name: pageData.name,
                count: 1,
              },
            ];
          }
        } else {
          sumupArray.push({
            id: uuid.v4(),
            name: criteria,
            count: 1,
            message: message,
            special_cases: specialCases,
            groups: [
              {
                id: uuid.v4(),
                name: pageData.name,
                count: 1,
              },
            ],
          });
        }
      });
    });

    const compare = (a, b) => {
      if (a.name > b.name) {
        return 1;
      }

      if (a.name < b.name) {
        return -1;
      }

      return 0;
    };

    setSumup(sumupArray.sort(compare));
    setIsLoading(false);

    return () => setSumup([]);
  }, [pagesData, wcagCriterias, csvData]);

  return (
    <Layout>
      <SEO title="Testen" />
      <h2>Testen</h2>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <Table>
            <colgroup>
              <col className="col-criteria" />
              <col className="col-criteria" />
              <col />
              <col className="col-groups" />
            </colgroup>
            <TableCaption>Samlet antal fejl fordelt på {sumup.length} kriterier</TableCaption>
            <TableThead>
              <TableTr>
                <TableTh alignRight>Kriterie</TableTh>
                <TableTh alignRight>Fejl</TableTh>
                <TableTh>Beskrivelse af fejl</TableTh>
                <TableTh>Sider</TableTh>
              </TableTr>
            </TableThead>
            <TableTbody>
              {sumup.map(row => {
                return (
                  <TableTr key={row.id}>
                    <TableTd thText="Kriterie" alignRight>
                      {row.name}
                    </TableTd>
                    <TableTd thText="Fejl" alignRight>
                      {row.count}
                    </TableTd>
                    <TableTd thText="Beskrivelse af fejl">
                      <div>{row.message}</div>
                      {row.special_cases && row.special_cases[0].items && (
                        <ul>
                          {row.special_cases[0].items.map((special_case, index) => (
                            <li key={`${row.id}-${index}`}>
                              <small>{special_case.text}</small>
                            </li>
                          ))}
                        </ul>
                      )}
                    </TableTd>
                    <TableTd thText="Sider">
                      <Collapse id={uuid.v4()}>
                        <CollapseButton>
                          Vis sider ({row.groups.length} ud af {pagesData.length})
                        </CollapseButton>
                        <CollapseContent>
                          {row.groups.map(group => {
                            return (
                              <div key={group.id}>
                                {group.name} ({group.count})
                              </div>
                            );
                          })}
                        </CollapseContent>
                      </Collapse>
                    </TableTd>
                  </TableTr>
                );
              })}
            </TableTbody>
          </Table>
        </>
      )}
    </Layout>
  );
};

export default TestPage;

export const query = graphql`
  query testPageQuery {
    allPagesJson {
      edges {
        node {
          ...pagesDataQueryFragment
        }
      }
    }

    allCsvCsv {
      nodes {
        data {
          code
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }

    allDataJson {
      edges {
        node {
          ...wcagDataQueryFragment
        }
      }
    }
  }
`;
