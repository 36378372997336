import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FiLoader } from "react-icons/fi";

import VisuallyHidden from "../visually-hidden/index";

import "./spinner.scss";

export const Spinner = ({ small, ...props }) => (
  <div className={classNames("spinner", { "spinner--small": small })} {...props} aria-live="polite">
    <FiLoader className="spinner__icon" aria-hidden="true" />
    <VisuallyHidden>Content is loading</VisuallyHidden>
  </div>
);

Spinner.propTypes = {
  small: PropTypes.bool,
};
