import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

import "./collapse.scss";

const CollapseContext = createContext({ id: "", isOpen: false, toggle: () => {} });

export const Collapse = ({ id, initialIsOpen = false, ...props }) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  return (
    <CollapseContext.Provider
      value={{
        isOpen,
        id,
        toggle: e => {
          setIsOpen(previous => !previous);
          e.preventDefault();
        },
      }}
      {...props}
    />
  );
};

export const CollapseButton = props => {
  return (
    <CollapseContext.Consumer>
      {value => (
        <button
          className="collapse-button"
          onClick={value.toggle}
          aria-expanded={value.isOpen}
          aria-controls={value.id}
          {...props}
        />
      )}
    </CollapseContext.Consumer>
  );
};

export const CollapseContent = props => {
  return (
    <CollapseContext.Consumer>
      {value => (
        <div id={value.id} className="collapse-content" aria-hidden={!value.isOpen} {...props} />
      )}
    </CollapseContext.Consumer>
  );
};

const sharedProps = {
  children: PropTypes.node.isRequired,
};

Collapse.propTypes = {
  id: PropTypes.string.isRequired,
  ...sharedProps,
};

CollapseButton.propTypes = {
  ...sharedProps,
};

CollapseContent.propTypes = {
  ...sharedProps,
};
